<template>
  <div class="jurisdiction-details">
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/jurisdiction' }">权限配置</el-breadcrumb-item>
        <el-breadcrumb-item>配置权限</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 表单数据 -->
    <div class="myform">
      <el-form :model="jurisdictionForm">
        <div class="form-top">
          <el-form-item label="商品管理权限：">
            <el-checkbox v-model="jurisdictionForm.goods.addGoods" label="新增编辑商品"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.goods.upAndDown" label="上下架商品"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.goods.delGoods" label="删除商品"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.goods.addAudio" label="新增编辑课程"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.goods.delAudio" label="删除课程"></el-checkbox>
          </el-form-item>
          <el-form-item label="机构管理权限：">
            <el-checkbox v-model="jurisdictionForm.chain.addChain" label="新增编辑机构"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.chain.delChain" label="删除机构"></el-checkbox>
          </el-form-item>
          <el-form-item label="用户管理权限：">
            <el-checkbox v-model="jurisdictionForm.user.addUser" label="新增编辑用户"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.user.stopUser" label="禁用用户"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.user.delUser" label="删除用户"></el-checkbox>
          </el-form-item>
          <el-form-item label="订单管理权限：">
            <el-checkbox v-model="jurisdictionForm.order.getOrder" label="查看公司订单"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.order.delOrder" label="删除订单"></el-checkbox>
          </el-form-item>
          <el-form-item label="营销管理权限：">
            <el-checkbox v-model="jurisdictionForm.marketing.addDiscount" label="新增编辑优惠券"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.marketing.upDiscount" label="发布优惠券"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.marketing.delDiscount" label="删除优惠券"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.marketing.addGroup" label="新增编辑拼团"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.marketing.upGroup" label="发布拼团"></el-checkbox>
            <el-checkbox v-model="jurisdictionForm.marketing.delGroup" label="删除拼团"></el-checkbox>
          </el-form-item>
        </div>
        <div class="form-button">
          <el-form-item>
            <el-button type="warning" size="mini" @click="submitForm">确定</el-button>
            <el-button size="mini" @click="resetForm">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { addJurisdiction, detailJurisdiction } from '../../../request/api'

export default {
  data () {
    return {
      jurisdictionForm: {
        shop_jurisdiction_add_id: '',
        goods: {
          addGoods: false,
          upAndDown: false,
          delGoods: false,
          addAudio: false,
          delAudio: false
        },
        chain: {
          addChain: false,
          delChain: false
        },
        user: {
          addUser: false,
          stopUser: false,
          delUser: false
        },
        order: {
          getOrder: false,
          delOrder: false
        },
        marketing: {
          addDiscount: false,
          upDiscount: false,
          delDiscount: false,
          addGroup: false,
          upGroup: false,
          delGroup: false
        }
      }
    }
  },
  methods: {
    async init () {
      const res = await detailJurisdiction(this.jurisdictionForm.shop_jurisdiction_add_id)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.jurisdictionForm = (res.data.data)
    },
    // 确定
    async submitForm () {
      let postData = {
        shop_jurisdiction_add_id: this.jurisdictionForm.shop_jurisdiction_add_id,
        goods: JSON.stringify(this.jurisdictionForm.goods),
        chain: JSON.stringify(this.jurisdictionForm.chain),
        user: JSON.stringify(this.jurisdictionForm.user),
        order: JSON.stringify(this.jurisdictionForm.order),
        marketing: JSON.stringify(this.jurisdictionForm.marketing)
      }
      const res = await addJurisdiction(postData)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.$message.success('修改成功')
      this.$router.push({ path: '/user/jurisdiction' })
    },
    // 返回
    resetForm () {
      this.$router.back()
    }
  },
  mounted () {
    this.init()
  },
  created () {
    this.jurisdictionForm.shop_jurisdiction_add_id = this.$route.query.shop_jurisdiction_add_id
  }
}
</script>

<style lang="less" scoped>
.jurisdiction-details {
  margin: 10px;
  height: 100%;
  .title {
    padding: 10px;
    background: #fff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .myform {
    height: 100%;
    .form-top {
      background: #fff;
      padding: 22px;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .form-button {
      background: #fff;
      padding: 20px 0px 2px 20px;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 10px 0px;
    }
  }
}
</style>